import React from 'react';
import { useParams } from 'react-router-dom';

import GetBlogs from '../components/GetBlogs';
import GetMeta from '../components/GetMeta';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer/Footer';

const Blogs = () => {
  let { blog_id, meta_id } = useParams();

  return (
    <>
      <Navbar />
      <GetBlogs id={ blog_id }/>
      <Footer />
      <GetMeta page={ meta_id } />
    </>
  )
}

export default Blogs