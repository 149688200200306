import React from 'react'

//import GetInTouch from '../components/GetInTouch';
//import GetStarted from '../components/GetStarted';
import Header from '../components/Header';

import Slider from '../components/Slider';
import CarouselHome from '../components/CarouselHome';
import Outstanding from '../components/Outstanding';
import SearchByCity from '../components/Forms/SearchByCity';
import GetMeta from '../components/GetMeta';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer/Footer';

const Home = () => {

  return (
    <>
      <Navbar />
      <Header />
      <Outstanding />
      <Slider />
      <SearchByCity />
      <CarouselHome />
      <Footer/>
      <GetMeta page={1} />
    </>

  )
}

export default Home

/*
      <GetUniversalCookies />


<GetStarted />
      <GetInTouch />

*/